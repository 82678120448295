import React from "react";
import { graphql } from "gatsby";

import ContactConnect from "../../components/try-now/contact-connect";
import Layout from "../../components/shared/layout-light";

export const query = graphql`
  query {
    connect: file(
      relativePath: { eq: "shared/confirmation/cta-videos-sm.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1340, maxHeight: 880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function ContactConnectPage({ data }) {
  return (
    <Layout homeUrl="/try-now">
      <ContactConnect
        contactType="Customer"
        imageData={data.connect.childImageSharp.fluid}
        formIntro="With a few details, we'll be able to connect you with a real Outer Customer"
        quote="It was so helpful to speak to a real Outer customer before I purchased. I wish every company did this."
        quoteSource="Bethany  K."
      />
    </Layout>
  );
}
