import Img from "gatsby-image";
import React from "react";

import ContactForm from "../owner-since/contact-form";
import FeaturedInLogos from "../owner-since/featured-in";

const TRY_NOW_LIST_ID = "RsxcCa";
const TRY_NOW_FORM_NAME = "Funnel - Try Now";

export default function ContactConnect({ imageData }) {
  return (
    <div className="content-container-max">
      <h1 className="text-blue-900 text-2xl mt-10 md:text-4xl md:mt-20 lg:px-20 2xl:px-0">
        Try Outer now for $0
      </h1>
      <div className="md:flex lg:px-20 2xl:px-0">
        <div className="md:w-1/2 lg:pr-10 xl:pr-36">
          <p className="text-blue-500 text-md mt-4 leading-tight md:mt-8 md:text-xl">
            With a few details, we’ll be able to process your at-home trial.
          </p>
          <ContactForm
            listId={TRY_NOW_LIST_ID}
            formName={TRY_NOW_FORM_NAME}
            successUrl="/try-now/contact-success"
          />
        </div>
        <div className="mt-14 md:w-1/2 md:pl-10 lg:pl-5 md:mt-8 md:flex md:flex-col">
          <blockquote className="text-size-md text-blue-900 text-center md:text-2xl md:leading-snug lg:text-3xl 2xl:text-4xl">
            "The trial allowed us to build the confidence we needed in order to
            make our investment. Not surprisingly, the Outer Sofa did not
            disappoint."
          </blockquote>
          <p className="text-sm text-blue-500 text-center mt-2 mb-7 md:mt-7 md:text-xl 2xl:text-2xl">
            Bethany K.
          </p>
          <Img className="xl:mt-auto" fluid={imageData} alt="Bethany K." />
        </div>
      </div>
      <FeaturedInLogos />
    </div>
  );
}
