import { navigate } from "gatsby";
import React, { useState } from "react";

import Button from "./button";
import * as Broker from "../../utils/broker";
import * as Analytics from "../../utils/analytics";
import CheckBox from "./checkbox";

const OWNER_SINCE_LIST_ID = "SnbYnd";
const OWNER_SINCE_FORM_NAME = "Funnel - Outer Owner Since";
const INPUT_STYLES =
  "rounded w-full text-blue-900 text-sm border-solid border px-5 py-2 placeholder-blue-500 mb-5 md:py-2.5 md:text-lg md:mb-8";

export default function ContactForm({ listId, formName, successUrl }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [optIn, setOptIn] = useState(true);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  function handleFirstName(e) {
    if (formErrors.firstName) {
      setFormErrors({ ...formErrors, firstName: undefined });
    }
    setFirstName(e.target.value);
  }
  function handleLastName(e) {
    if (formErrors.lastName) {
      setFormErrors({ ...formErrors, lastName: undefined });
    }
    setLastName(e.target.value);
  }
  function handleEmail(e) {
    if (formErrors.email) {
      setFormErrors({ ...formErrors, email: undefined });
    }
    setEmail(e.target.value);
  }

  function handleOptIn() {
    setOptIn(!optIn);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const errors = {};

    if (!firstName) {
      errors.firstName = "First name is required.";
    }
    if (!lastName) {
      errors.lastName = "Last name is required.";
    }

    // minimal email validation
    if (email.match(/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/) === null) {
      errors.email = "A valid email is required.";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    const user = {
      firstName,
      lastName,
      email,
    };

    try {
      setDisabled(true);

      // TODO make this form generic from the funnel side
      const brokerListId = listId || OWNER_SINCE_LIST_ID;
      const brokerFormName = formName || OWNER_SINCE_FORM_NAME;

      const res = await Broker.subscribe(user, brokerListId, brokerFormName);

      if (res.status === 201) {
        Analytics.track("Lead Capture", {
          source: brokerFormName,
          marketingCommunication: optIn ? "Yes" : "No",
        });
        return navigate(successUrl || "/owner-since/contact-success");
      }
    } catch (err) {
      console.error(err);
    }

    setDisabled(false);
    setFormErrors({
      ...formErrors,
      submitError: "Unable to submit request. Please try again.",
    });
  }

  return (
    <form className="mt-8 md:mt-10" onSubmit={handleSubmit}>
      <p className="relative">
        {formErrors.firstName && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.firstName}
          </span>
        )}

        <input
          className={`${INPUT_STYLES} ${
            formErrors.firstName ? "border-red-500" : "border-blue-900 "
          }`}
          name="firstName"
          onChange={handleFirstName}
          placeholder="First Name"
          type="text"
        />
      </p>

      <p className="relative">
        {formErrors.lastName && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.lastName}
          </span>
        )}
        <input
          className={`${INPUT_STYLES} ${
            formErrors.lastName ? "border-red-500" : "border-blue-900 "
          }`}
          name="lastName"
          onChange={handleLastName}
          placeholder="Last Name"
          type="text"
        />
      </p>
      <p className="relative">
        {formErrors.email && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.email}
          </span>
        )}
        <input
          className={`${INPUT_STYLES} ${
            formErrors.email ? "border-red-500" : "border-blue-900"
          }`}
          name="email"
          onChange={handleEmail}
          placeholder="Email"
          type="text"
        />
      </p>
      <p className="text-sm text-blue-500 mb-8">
        I agree to receive:
        <CheckBox
          label="I want to receive emails about new product releases, events, and news from Outer."
          checked={optIn}
          onCheck={handleOptIn}
        />
      </p>
      <div className="relative">
        <Button fluid disabled={disabled}>
          Submit
        </Button>
        {formErrors.submitError && (
          <span className="text-xs text-red-700 absolute left-0 -top-4">
            {formErrors.submitError}
          </span>
        )}
      </div>
    </form>
  );
}
